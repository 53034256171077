import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute, RouterOutlet } from '@angular/router';

import {
  NgbNav,
  NgbNavChangeEvent,
  NgbNavItem,
  NgbNavLink
} from '@ng-bootstrap/ng-bootstrap';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { NavService } from 'admin/screens/tables/service/nav.service';
import { ButtonComponent, Go } from '@ui/legacy-lib';
import { TranslateModule } from '@ngx-translate/core';
import * as fromAppState from '../../+state';

@UntilDestroy()
@Component({
  selector: 'app-tables',
  templateUrl: './tables.component.html',
  styleUrls: ['./tables.component.scss'],
  standalone: true,
  imports: [
    ButtonComponent,
    NgbNav,
    RouterOutlet,
    TranslateModule,
    NgbNavItem,
    NgbNavLink
  ]
})
export class TablesComponent implements OnInit {
  private store = inject(Store);
  private route = inject(ActivatedRoute);
  private navService = inject(NavService);

  @ViewChild('nav', { static: true }) nav: NgbNav;

  ngOnInit() {
    this.navService.init(this.nav);

    this.route.firstChild?.url
      .pipe(untilDestroyed(this))
      .subscribe(url => this.nav?.select(url[0].path));
  }

  public onTabChange($event: NgbNavChangeEvent) {
    this.store.dispatch(
      new Go({
        path: ['tables', $event.nextId]
      })
    );
  }

  public onCreateLandlord() {
    this.store.dispatch(
      new Go({
        path: ['landlord/new/edit']
      })
    );
    this.store.dispatch(new fromAppState.CreateProduct());
  }
}
