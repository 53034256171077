import { Component, OnInit, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { Observable } from 'rxjs';

import { LandlordUser, Pagination } from '@ui/shared/models';
import { ActionState, Go } from '@ui/legacy-lib';
import { ClipboardService } from '@ui/legacy-lib';

import * as fromAppState from 'admin/+state';
import { NavService } from 'admin/screens/tables/service/nav.service';
import { ActivatedRoute } from '@angular/router';
import {
  AppInputDirective,
  FormFieldComponent,
  LoadingSpinnerComponent,
  NoContentComponent,
  PaginationComponent
} from '@ui/legacy-lib';
import { AutofocusDirective } from '@ui/legacy-lib';
import { ButtonComponent } from '@ui/legacy-lib';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { AsyncPipe } from '@angular/common';
import { ContextMenuComponent, ContextMenuItemComponent } from '@ui/legacy-lib';
import { DateTimePipe } from '@ui/legacy-lib';

const SEARCH_OPTIONS = [
  { name: 'general.email_address_l', value: 'EMAIL' },
  { name: 'search.name_l', value: 'NAME' },
  { name: 'search.user_id_l', value: 'USER_ID' }
];

@Component({
  selector: 'app-tables-landlord-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  imports: [
    ReactiveFormsModule,
    AppInputDirective,
    AutofocusDirective,
    FormFieldComponent,
    ButtonComponent,
    NgbAlert,
    AsyncPipe,
    ContextMenuComponent,
    ContextMenuItemComponent,
    TranslateModule,
    NoContentComponent,
    LoadingSpinnerComponent,
    PaginationComponent,
    DateTimePipe
  ],
  standalone: true
})
export class UsersComponent implements OnInit {
  headerArray = [
    'table.header_agent_email_l',
    'table.header_user_id_l',
    'table.header_user_enabled_l',
    'table.header_customer_id_l',
    'table.header_customer_ident_l',
    'table.header_last_login_l',
    'table.header_tenant_type_l',
    'table.header_customer_name_l',
    'table.header_user_name_l'
  ];
  private store = inject<Store<fromAppState.AppState>>(Store);
  private formBuilder = inject(FormBuilder);
  private translate = inject(TranslateService);
  private navService = inject(NavService);
  private route = inject(ActivatedRoute);

  public users$: Observable<LandlordUser[]>;
  public pagination$: Observable<Pagination>;
  public isLoading$: Observable<ActionState>;
  public searchForm: FormGroup;
  public searchOptions;

  users: LandlordUser[] = [];
  pageSize = 25;
  page = 0;
  private clipboardService = inject(ClipboardService);

  private get searchValue() {
    return this.searchForm?.get('searchValue');
  }

  public copyToClipBoard(value: string) {
    this.clipboardService.copyToClipboard(value);
  }

  ngOnInit() {
    this.users$ = this.store.select(fromAppState.getUsersData);
    this.isLoading$ = this.store.select(fromAppState.getLandlordActionState);
    this.pagination$ = this.store.select(fromAppState.getLandlordPagination);
    this.searchForm = this.formBuilder.group({
      searchValue: '',
      searchType: 'EMAIL'
    });

    this.searchOptions = SEARCH_OPTIONS.map(option => ({
      name: this.translate.instant(option.name),
      value: option.value
    }));

    if (this.route.snapshot.queryParams.email) {
      this.searchValue.patchValue(this.route.snapshot.queryParams.email);
    }

    this.store.dispatch(
      new fromAppState.FetchUsers({
        page: this.page,
        size: this.pageSize,
        searchTerm: this.searchValue?.value
      })
    );
  }

  onPageChange(page: number) {
    this.store.dispatch(
      new fromAppState.FetchUsers({
        page: page - 1,
        size: this.pageSize,
        searchTerm: this.searchValue?.value
      })
    );
  }

  changeEnabled(id: string, enabled: boolean) {
    this.store.dispatch(new fromAppState.ChangeUserEnabled(id, enabled));
  }

  onEditUser(id: string) {
    this.store.dispatch(
      new Go({
        path: [`landlord/${id}/edit`]
      })
    );
  }

  onCustomerId(id: string) {
    this.navService.changeTab('customers');
    this.store.dispatch(
      new Go({
        path: ['tables/landlord'],
        extras: { queryParams: { customerId: id } }
      })
    );
  }

  impersonateUser(email: string) {
    this.store.dispatch(new fromAppState.ImpersonateUser(email));
  }

  onSearch() {
    this.store.dispatch(
      new fromAppState.FetchUsers({
        page: this.page,
        size: this.pageSize,
        searchTerm: this.searchValue.value
      })
    );
  }
}
