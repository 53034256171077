import { Component, OnInit, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import { Invoice, Pagination } from '@ui/shared/models';

import { InvoiceService } from 'admin/core/services/invoice.service';

import * as frommAppState from 'admin/+state';
import { ActionState } from '@ui/legacy-lib';
import {
  AppInputDirective,
  FormFieldComponent,
  LoadingSpinnerComponent,
  NoContentComponent,
  PaginationComponent,
  SelectComponent
} from '@ui/legacy-lib';
import { AutofocusDirective } from '@ui/legacy-lib';
import { ButtonComponent } from '@ui/legacy-lib';
import { AsyncPipe } from '@angular/common';
import { ContextMenuComponent, ContextMenuItemComponent } from '@ui/legacy-lib';

const SEARCH_OPTIONS = [{ name: 'search.invoice_id_l', value: 'INVOICE_ID' }];

@Component({
  selector: 'app-tables-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
  imports: [
    ReactiveFormsModule,
    FormFieldComponent,
    AppInputDirective,
    AutofocusDirective,
    TranslateModule,
    ButtonComponent,
    AsyncPipe,
    ContextMenuComponent,
    ContextMenuItemComponent,
    NoContentComponent,
    LoadingSpinnerComponent,
    PaginationComponent,
    SelectComponent
  ],
  standalone: true
})
export class InvoicesComponent implements OnInit {
  private store = inject<Store<frommAppState.AppState>>(Store);
  private invoiceService = inject(InvoiceService);
  private formBuilder = inject(FormBuilder);
  private translate = inject(TranslateService);
  private route = inject(ActivatedRoute);

  public invoices$: Observable<Invoice[]>;
  public pagination$: Observable<Pagination>;
  public isLoading$: Observable<ActionState>;
  public searchForm: FormGroup;
  public searchOptions;
  public customerId: string;

  pageSize = 25;
  page = 0;
  headerArray = [
    'table.header_customer_id_l',
    'table.header_customer_name_l',
    'table.header_invoice_price_l',
    'table.header_invoice_canceled_l',
    'table.header_invoice_id_l'
  ];

  ngOnInit() {
    this.customerId = this.route.snapshot.queryParams.customerId || null;
    this.store.dispatch(
      new frommAppState.FetchInvoices({
        size: this.pageSize,
        page: this.page,
        customerId: this.customerId
      })
    );
    this.invoices$ = this.store.select(frommAppState.getInvoicesData);
    this.isLoading$ = this.store.select(frommAppState.getLandlordActionState);
    this.pagination$ = this.store.select(frommAppState.getLandlordPagination);
    this.searchForm = this.formBuilder.group({
      searchValue: '',
      searchType: 'INVOICE_ID'
    });

    this.searchOptions = SEARCH_OPTIONS.map(option => ({
      name: this.translate.instant(option.name),
      value: option.value
    }));
  }

  onPageChange(page: number) {
    this.store.dispatch(
      new frommAppState.FetchInvoices({
        page: page - 1,
        customerId: this.customerId
      })
    );
  }

  downloadInvoice(invoiceId: string) {
    this.invoiceService.downloadInvoice(invoiceId);
  }

  cancelInvoice(invoiceId: string) {
    this.store.dispatch(new frommAppState.CancelInvoice(invoiceId));
  }

  downloadCancelledInvoice(invoiceId: string) {
    this.invoiceService.downloadStorno(invoiceId);
  }

  onSearch(searchForm: FormGroup) {
    if (searchForm.value.searchValue === '') {
      this.store.dispatch(
        new frommAppState.FetchInvoices({
          page: this.page,
          size: this.pageSize
        })
      );
    } else {
      this.store.dispatch(
        new frommAppState.FetchInvoiceById(searchForm.value.searchValue)
      );
    }
  }
}
