import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';

import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { Observable } from 'rxjs';

import { Pagination, PropertySearcherUserBean } from '@ui/shared/models';

import { ModalService } from '@ui/legacy-lib';
import * as fromAppState from 'admin/+state';
import {
  AppInputDirective,
  FormFieldComponent,
  LoadingSpinnerComponent,
  NoContentComponent,
  PaginationComponent
} from '@ui/legacy-lib';
import { AutofocusDirective } from '@ui/legacy-lib';
import { ButtonComponent } from '@ui/legacy-lib';
import { AsyncPipe } from '@angular/common';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { ContextMenuComponent, ContextMenuItemComponent } from '@ui/legacy-lib';
import { DateTimePipe } from '@ui/legacy-lib';
import * as dialogConfig from './propertysearcher-dialog.config';

const SEARCH_OPTIONS = [
  { name: 'general.email_address_l', value: 'CUSTOMER_EMAIL' }
];

@Component({
  selector: 'app-tables-property-searcher-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  imports: [
    FormFieldComponent,
    AppInputDirective,
    AutofocusDirective,
    ButtonComponent,
    ReactiveFormsModule,
    TranslateModule,
    AsyncPipe,
    NgbTooltip,
    ContextMenuComponent,
    ContextMenuItemComponent,
    NoContentComponent,
    LoadingSpinnerComponent,
    PaginationComponent,
    DateTimePipe
  ],
  standalone: true
})
export class UsersComponent implements OnInit {
  private store = inject<Store<fromAppState.AppState>>(Store);
  private formBuilder = inject(FormBuilder);
  private translate = inject(TranslateService);
  private modalService = inject(ModalService);

  public users$: Observable<PropertySearcherUserBean[]>;
  public pagination$: Observable<Pagination>;
  public isLoading$: Observable<boolean>;
  public searchForm: FormGroup;
  public searchOptions;
  public currentDate = new Date().toISOString();

  pageSize = 25;
  page = 0;
  headerArray = [
    'table.header_user_id_l',
    'table.header_last_login_l',
    'table.header_email_l',
    'table.header_tenant_type_l',
    'table.header_tenant_has_active_search_profile_l'
  ];

  public get searchValue() {
    return this.searchForm.get('searchValue');
  }

  ngOnInit() {
    this.store.dispatch(
      new fromAppState.FetchPropertySearcherUsers({
        size: this.pageSize,
        page: this.page
      })
    );
    this.users$ = this.store.select(fromAppState.getPropertySearcherUsersData);
    this.isLoading$ = this.store.select(
      fromAppState.getPropertySearcherLoading
    );
    this.pagination$ = this.store.select(
      fromAppState.getPropertySearcherpagination
    );
    this.searchForm = this.formBuilder.group({
      searchValue: ''
    });

    this.searchOptions = SEARCH_OPTIONS.map(option => ({
      name: this.translate.instant(option.name),
      value: option.value
    }));
  }

  onPageChange(page: number) {
    this.store.dispatch(
      new fromAppState.FetchPropertySearcherUsers({ page: page - 1 })
    );
  }

  activatePropertySearcher(email: string) {
    this.store.dispatch(new fromAppState.ActivatePropertySearcher(email));
  }

  deletePropertySearcher(id: string) {
    this.modalService
      .openConfirmation({
        data: { ...dialogConfig.deletePropertySearcherConfirmation }
      })
      .onClose()
      .subscribe(() =>
        this.store.dispatch(new fromAppState.DeletePropertySearcher(id))
      );
  }

  impersonateUser(email: string) {
    this.store.dispatch(new fromAppState.ImpersonatePropertySearcher(email));
  }

  onSearch() {
    this.store.dispatch(
      new fromAppState.FetchPropertySearcherUsers({
        page: this.page,
        size: this.pageSize,
        searchTerm: this.searchValue.value
      })
    );
  }
}
